import { useEffect, useState, MutableRefObject } from 'react'

type LbMessageEvent = {
  isLbMessage: boolean
  [key: string]: unknown
}

export const calculateToCenterWindow = (w: number, h: number) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height
  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  return {
    left,
    top,
  }
}

// Send initial data to popup
export const postInitialDataToPopup = ({
  initialData,
  popup,
  skipOriginCheck,
}: {
  initialData?: any
  popup: Window
  skipOriginCheck?: boolean
}) => {
  if (!initialData) {
    return
  }

  const message: LbMessageEvent = {
    ...initialData,
    isLbMessage: true,
  }
  const targetOrigin = skipOriginCheck ? '*' : window.location.origin

  // better to dalay the postMessage to be sure popup is properly loaded
  setTimeout(() => {
    popup.postMessage(message, targetOrigin)
  }, 1500)
}

// Listen for message returned from popup
export const usePopupMessageReceiver = (
  popup: MutableRefObject<Window | null>
) => {
  const [dataFromPopup, setDataFromPopup] = useState<Record<string, any>>()

  const messageReceiverFn = function (event: MessageEvent<LbMessageEvent>) {
    if (event.data.isLbMessage) {
      // console.log('Data received back from popup: ', event.data)
      setDataFromPopup(event.data)
    }
  }
  useEffect(() => {
    if (popup) {
      window.addEventListener('message', messageReceiverFn)
    }
    return () => window.removeEventListener('message', messageReceiverFn)
  }, [popup])

  return {
    dataFromPopup,
  }
}

export const makePopupFeatures = (
  widthPx: number,
  heightPx: number
): string => {
  const positionValues = calculateToCenterWindow(widthPx, heightPx)

  const params: Record<string, string | number> = {
    scrollbars: 'no',
    resizable: 'no',
    status: 'no',
    toolbar: 'no',
    menubar: 'no',
    width: widthPx,
    height: heightPx,
    left: positionValues.left,
    top: positionValues.top,
  }

  return Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join(',')
}

// Calculate dynamic popup sizes to avoid overflow scrollbar
const defaultPopupWeight = 900
const defaultPopupHeight = 600

export const getPopupWeight = () => {
  const currentWidth = window.screen.availWidth
  if (currentWidth < defaultPopupWeight) {
    return (95 * currentWidth) / 100
  } else {
    return defaultPopupWeight
  }
}

export const getPopupHeight = () => {
  const currentHeight = window.screen.availHeight
  if (currentHeight < defaultPopupHeight) {
    return (90 * currentHeight) / 100
  } else {
    return defaultPopupHeight
  }
}
